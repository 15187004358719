var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.AddEditdialog),callback:function ($$v) {_vm.AddEditdialog=$$v},expression:"AddEditdialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t(_vm.formTitle)))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",staticClass:"mt-5",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Code"))+" ")]),_c('v-text-field',{attrs:{"rules":[
                  _vm.validationRules.required,
                  _vm.validationRules.maxLength50
                ],"solo":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.code),callback:function ($$v) {_vm.$set(_vm.editedItem, "code", $$v)},expression:"editedItem.code"}}),(_vm.validation_errors.code)?_c('div',_vm._l((_vm.validation_errors.code),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Name"))+" ")]),_c('v-text-field',{attrs:{"rules":[
                  _vm.validationRules.required,
                  _vm.validationRules.maxLength100
                ],"solo":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.en.name),callback:function ($$v) {_vm.$set(_vm.editedItem.en, "name", $$v)},expression:"editedItem.en.name"}}),(_vm.validation_errors.en)?_c('div',_vm._l((_vm.validation_errors.en.name),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Arabic Name")))]),_c('v-text-field',{attrs:{"solo":"","rules":[
                  _vm.validationRules.required,
                  _vm.validationRules.maxLength100,
                  _vm.CheckArabicCharactersOnly(_vm.editedItem.ar.name)
                ]},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.ar.name),callback:function ($$v) {_vm.$set(_vm.editedItem.ar, "name", $$v)},expression:"editedItem.ar.name"}}),(_vm.validation_errors.ar)?_c('div',_vm._l((_vm.validation_errors.ar.name),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Notes"))+" ")]),_c('v-textarea',{attrs:{"solo":"","rules":[_vm.validationRules.maxLength1000],"rows":"3"},model:{value:(_vm.editedItem.notes),callback:function ($$v) {_vm.$set(_vm.editedItem, "notes", $$v)},expression:"editedItem.notes"}}),(_vm.validation_errors.notes)?_c('div',_vm._l((_vm.validation_errors.notes),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"modal-btn-cancel",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("Close")))]),_c('v-btn',{staticClass:"modal-btn-save",attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }